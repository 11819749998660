import { http } from '@pier88health/p8h-common'
import axios from 'axios'
import DBConfig from '@CONFIG';
import { getToken } from '@UTILS/authority';

const isUseProxy = process.env.NODE_ENV === 'development'

http.init({
  baseUrl: isUseProxy ? '/svc' : `${DBConfig.baseUrl}/svc`,
  axios,
  logEnable: false,
  errorKey: 'error',
  getLanguage: () => 'zh',
  getToken,
})
const { fetchRequest, uploadRequest } = http

const apiService = {
  loginH5: fetchRequest('/User/H5Login', 'POST'),

  getWeChatSignature: fetchRequest('/PubSts/GetWeChatSignature', 'GET'),

  getPubOpportunityDetails: fetchRequest(
    '/Opportunity/GetOpportunityDetails',
    'GET'
  ),
  getRequestContact: fetchRequest('/Application/RequestContact', 'GET'),

  getPubProfile: fetchRequest('/PubProfile/GetPublicProfile', 'GET'),
  getPubFeedItem: fetchRequest('/PubFeedItem/GetFeedItemDetails', 'GET'),
  getPubComments: fetchRequest('/PubFeedItem/GetComments', 'GET'),
  getPubSummary: fetchRequest('/PubConnection/GetSummary', 'GET'),
  getPubAward: fetchRequest('/PubAward/GetAwardSummary', 'GET'),
  getPubPublication: fetchRequest(
    '/PubPublication/GetPublicationSummary',
    'GET'
  ),
  getLikes: fetchRequest('/PubFeedItem/GetLikes', 'GET'),
  getPubOpportunity: fetchRequest(
    '/PubOpportunity/GetOpportunityDetails',
    'GET'
  ),

  getUserAward: fetchRequest('/AwardAdmin/GetAwardSummary', 'GET'),
  getUserPublication: fetchRequest(
    '/PublicationAdmin/GetPublicationSummary',
    'GET'
  ),

  getUserList: fetchRequest('/UserAdmin/GetUsers', 'GET'),
  countUsers: fetchRequest('/UserAdmin/CountUsers', 'GET'),
  getCurrentUser: fetchRequest('/Profile/LandingProfile', 'GET'),
  getUserDetail: fetchRequest('/UserAdmin/GetUserDetail', 'GET'),
  getGroupTypes: fetchRequest('/UserAdmin/GetGroupTypes', 'GET'),
  passVerification: fetchRequest('/UserAdmin/PassVerification', 'POST'),
  declineVerification: fetchRequest('/UserAdmin/DeclineVerification', 'POST'),
  suspendUser: fetchRequest('/UserAdmin/SuspendUser', 'POST'),
  unsuspendUser: fetchRequest('/UserAdmin/UnsuspendUser', 'POST'),
  setUserIdentities: fetchRequest('/UserAdmin/SetUserIdentities', 'POST'),
  getChannelInfo: fetchRequest('/UserAdmin/GetChannelInfo', 'GET'),
  passServiceVerification: fetchRequest('/UserAdmin/PassServiceVerification', 'POST'),
  declineServiceVerification: fetchRequest('/UserAdmin/DeclineServiceVerification', 'POST'),

  getContentList: fetchRequest('/FeedItemAdmin/GetGlobalFeedItems', 'GET'),
  getSearchUser: fetchRequest('/UserAdmin/SearchUser', 'GET'),
  blockFeedItem: fetchRequest('/FeedItemAdmin/BlockFeedItem', 'POST'),
  unBlockFeedItem: fetchRequest('/FeedItemAdmin/UnBlockFeedItem', 'POST'),

  getOwnContentList: fetchRequest('/FeedItemAdmin/GetOwnFeedItems', 'GET'),
  getContentDetail: fetchRequest('/FeedItemAdmin/GetFeedItemDetails', 'GET'),
  deleteOwnFeedItem: fetchRequest('/FeedItemAdmin/DeleteOwnFeedItem', 'POST'),

  getArticleList: fetchRequest('/FeedItemAdmin/GetArticleFeedItems', 'GET'),
  archiveArticle: fetchRequest('/FeedItemAdmin/Archive', 'POST'),
  recycleArticle: fetchRequest('/FeedItemAdmin/Recycle', 'POST'),

  saveTrigger: fetchRequest('/Trigger/Save', 'POST'),
  getTriggers: fetchRequest('/Trigger/GetTriggers', 'GET'),
  getTriggerDetail: fetchRequest('/Trigger/GetTriggerById', 'GET'),
  activeTrigger: fetchRequest('/Trigger/Activate', 'POST'),
  deactivateTrigger: fetchRequest('/Trigger/Deactivate', 'POST'),
  archiveTrigger: fetchRequest('/Trigger/Archive', 'POST'),
  saveOptEvent: fetchRequest('/OpEvent/SaveOptEvent', 'POST'),
  getRouteTypes: fetchRequest('/OpEvent/GetRouteTypes', 'GET'),
  getEvents: fetchRequest('/OpEvent/GetEvents', 'GET'),
  archiveEvents: fetchRequest('/OpEvent/Archive', 'POST'),
  recycleEvents: fetchRequest('/OpEvent/Recycle', 'POST'),
  getEventDetail: fetchRequest('/OpEvent/GetEventDetail', 'GET'),
  exportOpportunityToFeed: fetchRequest('/Opportunity/ExportToFeed', 'POST'),
  exportGuideToFeed: fetchRequest('/GuideAdmin/ExportToFeed', 'POST'),
  exportCourseToFeed: fetchRequest('/CourseAdmin/ExportToFeed', 'POST'),
  getSpotTypes: fetchRequest('/OpEvent/GetSpotTypes', 'GET'),

  getModeratorApplicationsList: fetchRequest(
    '/Group/GetModeratorApplications',
    'GET'
  ),
  approveModeratorApplication: fetchRequest(
    '/Group/ApproveModeratorApplication',
    'POST'
  ),
  getModeratorDenyReasons: fetchRequest(
    '/Group/GetModeratorDenyReasons',
    'GET'
  ),
  denyModeratorApplication: fetchRequest(
    '/Group/DenyModeratorApplication',
    'POST'
  ),
  getGroups: fetchRequest('/Group/GetGroups', 'GET'),
  getGroupDetail: fetchRequest('/Group/GetGroup', 'GET'),
  getGroupModerators: fetchRequest('/Group/GetModerators', 'GET'),
  getBusinessType: fetchRequest('/Group/GetBusinessType', 'GET'),
  getGroupFollowers: fetchRequest('/Group/GetGroupFollowers', 'GET'),
  getGroupFeedItems: fetchRequest('/FeedItemAdmin/GetGroupFeedItems', 'GET'),
  getAssociationMembers: fetchRequest('/Member/GetAssociationMembers', 'GET'),
  searchMember: fetchRequest('/Member/SearchMember', 'GET'),
  getInstitutionMembersForAdmin: fetchRequest('/Member/GetInstitutionMembersForAdmin', 'GET'),
  countInstitutionMembersForAdmin: fetchRequest('/Member/CountInstitutionMembersForAdmin', 'GET'),
  remindMemberToLogin: fetchRequest('/Member/RemindMemberToLogin', 'POST'),
  remindeAllMembersToLoginByGroupId: fetchRequest('/Member/RemindeAllMembersToLoginByGroupId', 'POST'),
  getNewsFeedByGroupId: fetchRequest(
    '/FeedItemAdmin/GetNewsFeedByGroupId',
    'GET'
  ),
  getCustomerHospitals: fetchRequest('/Group/GetCustomerHospitals', 'GET'),
  getGroupsByClassifications: fetchRequest('/Group/GetGroupsByClassifications', 'POST'),

  getGroupLookup: fetchRequest('/GroupLookup/GroupLookup', 'GET'),
  getEmployeeSize: fetchRequest('/Group/EmployeeSizeOptions', 'GET'),

  getCountries: fetchRequest('/Geo/GetCountries', 'GET'),
  getChinaProvinces: fetchRequest('/Geo/GetChinaProvinces', 'GET'),
  getUSStates: fetchRequest('/Geo/GetUSStates', 'GET'),

  getAllCountries: fetchRequest('/Geo/GetAllCountries', 'GET'),
  getStatesByCountryCode: fetchRequest('/Geo/GetStatesByCountryCode', 'GET'),
  getCities: fetchRequest('/Geo/GetCitiesByStateOrCountryCode', 'GET'),
  createGroup: fetchRequest('/Group/CreateGroup', 'POST'),
  updateInfo: fetchRequest('/Group/UpdateInfo', 'POST'),

  getTopic: fetchRequest('/Topic/Lookup', 'GET'),
  savePost: fetchRequest('/PostAdmin/SavePost', 'POST'),
  publishArticle: fetchRequest('/FeedItemAdmin/Publish', 'POST'),
  addImages: uploadRequest('/PostAdmin/AddImages'),
  removeImage: fetchRequest('/PostAdmin/RemoveImage', 'POST'),

  requestAuthCodeBind: fetchRequest(
    '/Profile/RequestAuthCodePhoneBind',
    'POST'
  ),
  bindPhone: fetchRequest('/Profile/BindPhone', 'POST'),

  signin: fetchRequest('/User/AdminLogin', 'POST'),
  smsCode: fetchRequest('/User/AdminSMSCode', 'POST'),
  logout: fetchRequest('/UserAdmin/Logout', 'POST'),
  authCode: fetchRequest('/User/ReqSelfRegByPhone', 'POST'),
  requestAuthCode: fetchRequest('/User/RequestAuthCode', 'POST'),
  regWithCode: fetchRequest('/User/RegisterWithAuthCode', 'POST'),
  verifyActionToken: fetchRequest('/User/VerifyActionToken', 'POST'),
  createNewUserByEmail: fetchRequest('/User/CreateNewUserByEmail', 'POST'),
  setPassword: fetchRequest('/User/SetPassword', 'POST'),
  requestAuthCodeByEmail: fetchRequest('/User/RequestAuthCodeByEmail', 'POST'),
  emailResetPassword: fetchRequest('/User/ResetPassword', 'POST'),
  loginByAuthCode: fetchRequest('/User/LoginByAuthCode', 'POST'),
  registerNewUser: fetchRequest('/User/RegisterNewUser', 'POST'),
  registerUserByActionTokenId: fetchRequest(
    '/User/RegisterUserByActionTokenId',
    'GET'
  ),
  findUser: fetchRequest('/User/FindUser', 'POST'),
  getUser: fetchRequest('/User/GetUser', 'POST'),

  findUserToImport: fetchRequest('/Profile/FindUserToImport', 'POST'),
  importMeFromStagedUser: fetchRequest(
    '/Profile/ImportMeFromStagedUser',
    'POST'
  ),

  getSpecialties: fetchRequest('/Specialty/GetSpecialties', 'GET'),
  getSubSpecialties: fetchRequest('/Specialty/GetSubSpecialties', 'GET'),
  getDepartments: fetchRequest('/DepartmentLookup/GetDepartments', 'GET'),
  getDiseaseList: fetchRequest('/Topic/Lookup', 'GET'),
  getSubDepartments: fetchRequest('/DepartmentLookup/GetSubDepartments', 'GET'),
  getHospitals: fetchRequest('/GroupLookup/GetHospitals', 'GET'),
  getSchools: fetchRequest('/GroupLookup/GetSchools', 'GET'),
  getPositions: fetchRequest('/UserAdmin/GetPositionOptions', 'GET'),
  getTitleOptions: fetchRequest('/UserAdmin/GetTitleOptions', 'GET'),
  getChinaDegrees: fetchRequest('/User/GetChinaDegrees', 'GET'),
  getUSADegrees: fetchRequest('/User/GetUSADegrees', 'GET'),
  getMyRepGroup: fetchRequest('/Opportunity/GetMyRepGroup', 'GET'),

  getNewsFeed: fetchRequest('/FeedItem/GetNewsFeed', 'GET'),
  getFeedItemDetails: fetchRequest('/FeedItem/GetFeedItemDetails', 'GET'),

  submitReport: fetchRequest('/FeedItem/Report', 'POST'),

  createPost: uploadRequest('/Post/CreatePost'),
  creteGroupWidePost: uploadRequest('/Post/CreteGroupWidePost'),
  deleteMyItem: fetchRequest('/FeedItem/DeleteMyItem', 'POST'),
  getLikesGroup: fetchRequest('/FeedItem/GetLikes', 'GET'),
  likePost: fetchRequest('/FeedItem/Like', 'POST'),
  unLikePost: fetchRequest('/FeedItem/Unlike', 'POST'),
  commentPost: fetchRequest('/FeedItem/Comment', 'POST'),
  sharePost: fetchRequest('/FeedItem/Share', 'POST'),
  shareToChatSessions: fetchRequest('/FeedItem/ShareToChatSessions', 'POST'),
  shareWithFriends: fetchRequest('/FeedItem/ShareWithFriends', 'POST'),
  translate: fetchRequest('/FeedItem/Translate', 'POST'),
  shareTranslate: fetchRequest('/FeedItem/ShareTranslate', 'POST'),
  deleteComment: fetchRequest('/Comment/DeleteComment', 'POST'),
  impersonateReplyComment: fetchRequest('/FeedItemAdmin/ImpersonateReplyComment', 'POST'),
  replyComment: fetchRequest('/FeedItem/ReplyComment', 'POST'),
  commentTranslate: fetchRequest('/Comment/Translate', 'POST'),
  getComments: fetchRequest('/FeedItem/GetComments', 'GET'),
  getActivities: fetchRequest('/FeedItem/GetMemberActivity', 'GET'),
  getFriendNumber: fetchRequest('/Connection/GetFriendNumber', 'GET'),
  getAwardSummary: fetchRequest('/Award/GetAwardSummary', 'GET'),
  getPublicationSummary: fetchRequest(
    '/Publication/GetPublicationSummary',
    'GET'
  ),
  getGroupOpportunities: fetchRequest(
    '/Opportunity/GetGroupOpportunities',
    'GET'
  ),
  getGroupNewsFeed: fetchRequest('/FeedItem/GetGroupNewsFeed', 'GET'),

  followGroup: fetchRequest('/Connection/FollowGroup', 'POST'),
  unFollowGroup: fetchRequest('/Connection/UnfollowGroup', 'POST'),
  getSummary: fetchRequest('/Connection/GetSummary', 'GET'),
  searchConnection: fetchRequest('/Connection/Search', 'GET'),
  follow: fetchRequest('/Connection/Follow', 'POST'),
  followTopic: fetchRequest('/Connection/FollowTopic', 'POST'),
  unfollow: fetchRequest('/Connection/Unfollow', 'POST'),
  getFollowers: fetchRequest('/Connection/GetFollowers', 'GET'),
  getFollowees: fetchRequest('/Connection/GetFollowees', 'GET'),
  isFollowedByMe: fetchRequest('/Connection/IsFollowedByMe', 'GET'),
  getFriends: fetchRequest('/Connection/GetFriends', 'GET'),
  addFriend: fetchRequest('/Connection/AddFriend', 'POST'),
  delFriend: fetchRequest('/Connection/Defriend', 'POST'),
  getMyFriendRequest: fetchRequest('/Connection/GetMyFriendRequest', 'GET'),
  accept: fetchRequest('/Connection/Accept', 'POST'),
  ignore: fetchRequest('/Connection/Ignore', 'POST'),
  skipFriends: fetchRequest('/Connection/Skip', 'POST'),
  block: fetchRequest('/Connection/Block', 'POST'),
  unblock: fetchRequest('/Connection/Unblock', 'POST'),
  getFollowSuggests: fetchRequest('/Connection/GetFollowSuggests', 'GET'),
  getFriendSuggests: fetchRequest('/Connection/GetFriendSuggests', 'POST'),
  checkFriendsByPhoneContacts: fetchRequest(
    '/Connection/CheckFriendsByPhoneContacts',
    'POST'
  ),

  getProfile: fetchRequest('/Profile/GetUserProfile', 'GET'),
  getLandingProfile: fetchRequest('/Profile/LandingProfile', 'GET'),
  updateMyProfile: fetchRequest('/Profile/UpdateMyProfile', 'POST'),
  updateName: fetchRequest('/Profile/SetMyFullName', 'POST'),
  updateUserType: fetchRequest('/Profile/SetMyUserType', 'POST'),
  setMyAffliation: fetchRequest('/Profile/SetMyAffliation', 'POST'),
  setMySchool: fetchRequest('/Profile/SetMySchool', 'POST'),
  uploadMyAvatar: uploadRequest('/Profile/UploadMyAvatar'),
  uploadMyCertificate: uploadRequest('/Profile/UploadMyCertificate'),
  deleteMyCertificate: fetchRequest('/Profile/DeleteMyCertificate', 'POST'),
  setInAppStatus: fetchRequest('/Profile/SetInAppStatus', 'POST'),
  verifyUserPassword: fetchRequest('/Profile/VerifyUserPassword', 'POST'),
  resetUserPassword: fetchRequest('/Profile/ResetUserPassword', 'POST'),

  getUserEducations: fetchRequest('/Experience/GetUserEducations', 'GET'),
  getUserProfessions: fetchRequest('/Experience/GetUserProfessions', 'GET'),
  getEducation: fetchRequest('/Experience/GetEducation', 'GET'),
  getProfession: fetchRequest('/Experience/GetProfession', 'GET'),

  enterSessionById: fetchRequest('/Chat/EnterSessionById', 'POST'),
  enterSessionByMemberIds: fetchRequest(
    '/Chat/EnterSessionByMemberIds',
    'POST'
  ),
  createMessage: fetchRequest('/Chat/CreateMessage', 'POST'),
  postUploadedImageMessage: fetchRequest('/Chat/PostUploadedImageMessage', 'POST'),
  postImageMessage: uploadRequest('/Chat/PostImageMessage'),
  getMessages: fetchRequest('/Chat/GetMessages', 'GET'),
  getNewMessages: fetchRequest('/Chat/GetNewMessages', 'GET'),
  getSessions: fetchRequest('/Chat/GetSessions', 'GET'),
  clearHistory: fetchRequest('/Chat/ClearHistory', 'POST'),
  addParticipants: fetchRequest('/Chat/AddParticipants', 'POST'),
  removeParticipants: fetchRequest('/Chat/RemoveParticipants', 'POST'),
  muteSession: fetchRequest('/Chat/MuteSession', 'POST'),
  unmuteSession: fetchRequest('/Chat/UnmuteSession', 'POST'),
  leaveSession: fetchRequest('/Chat/LeaveSession', 'POST'),
  searchMembersToAdd: fetchRequest('/Chat/SearchMembersToAdd', 'GET'),
  getMembersToAdd: fetchRequest('/Chat/GetMembersToAdd', 'GET'),

  getUptodateCredential: fetchRequest('/Member/GetUptodateCredential', 'GET'),

  getBellAlerts: fetchRequest('/Notification/GetBellAlerts', 'GET'),
  getMyOutstandingNumbers: fetchRequest(
    '/Profile/GetMyOutstandingNumbers',
    'GET'
  ),
  archiveBellAlert: fetchRequest('/Notification/ArchiveBellAlert', 'POST'),

  submitAppFeedback: fetchRequest('/AppFeedback/SubmitFeedback', 'POST'),

  getOpportunityList: fetchRequest(
    '/OpportunityAdmin/GetOpportunityList',
    'GET'
  ),
  getOwnOpportunityList: fetchRequest(
    '/OpportunityAdmin/GetOwnOpportunityList',
    'GET'
  ),
  saveOpportunity: fetchRequest('/OpportunityAdmin/SaveOpportunity', 'POST'),
  updateOpportunityTags: fetchRequest('/OpportunityAdmin/UpdateTags', 'POST'),

  getOpportunityTypes: fetchRequest('/Opportunity/GetOpportunityTypes', 'GET'),
  createOpportunity: fetchRequest('/Opportunity/Save', 'POST'),
  getMyOpportunities: fetchRequest('/Opportunity/GetMyOpportunities', 'GET'),
  getOpportunityFeed: fetchRequest('/Opportunity/GetOpportunityFeed', 'GET'),
  getOpportunitySuggests: fetchRequest(
    '/Opportunity/GetOpportunitySuggests',
    'GET'
  ),

  getOutbounds: fetchRequest('/Application/GetOutbounds', 'GET'),
  getInbounds: fetchRequest('/Application/GetInbounds', 'GET'),
  getApplicationDetails: fetchRequest(
    '/Application/GetApplicationDetails',
    'GET'
  ),
  getOpportunityDetails: fetchRequest(
    '/OpportunityAdmin/GetOpportunityDetails',
    'GET'
  ),
  getDraftOpportunity: fetchRequest('/Opportunity/GetDraftOpportunity', 'GET'),
  // recycleOpportunity: fetchRequest('/Opportunity/Recycle', 'POST'),
  publishOpportunity: fetchRequest('/Opportunity/Publish', 'POST'),
  getApplicationsByOpportunity: fetchRequest(
    '/ApplicationAdmin/GetApplicationsByOpportunity',
    'GET'
  ),
  recycleOpportunity: fetchRequest(
    '/OpportunityAdmin/RecycleOpportunity',
    'POST'
  ),
  closeOpportunity: fetchRequest('/OpportunityAdmin/CloseOpportunity', 'POST'),
  submitApplication: fetchRequest('/Application/Submit', 'POST'),
  getApplicantComments: fetchRequest('/Application/GetComments', 'GET'),
  addApplicantComment: fetchRequest('/Application/Comment', 'POST'),
  makeOffer: fetchRequest('/Application/MakeOffer', 'POST'),
  repDecline: fetchRequest('/Application/RepDecline', 'POST'),
  getDeclineReasons: fetchRequest('/Application/GetDeclineReasons', 'Get'),
  getDeclineOfferReasons: fetchRequest(
    '/Application/GetDeclineOfferReasons',
    'Get'
  ),
  acceptOffer: fetchRequest('/Application/AcceptOffer', 'POST'),
  declineOffer: fetchRequest('/Application/DeclineOffer', 'POST'),
  getAdminFeaturedDepartments: fetchRequest('/GuideAdmin/GetFeaturedDepartments', 'Get'),
  saveGuide: fetchRequest('/GuideAdmin/SaveGuide', 'POST'),
  saveGuideData: fetchRequest('/GuideAdmin/SaveGuideData', 'POST'),
  updateGuideTags: fetchRequest('/GuideAdmin/UpdateTags', 'POST'),
  getAdminGuideList: fetchRequest('/GuideAdmin/GetGuideList', 'GET'),
  getGuide: fetchRequest('/GuideAdmin/GetGuide', 'GET'),
  getPubGuide: fetchRequest('/PubGuide/GetGuide', 'GET'),
  archiveGuide: fetchRequest('/GuideAdmin/Archive', 'POST'),
  recycleGuide: fetchRequest('/GuideAdmin/Recycle', 'POST'),
  publishGuide: fetchRequest('/GuideAdmin/Publish', 'POST'),
  getSpecialtyList: fetchRequest('/Specialty/GetSpecialtiesByName', 'GET'),
  getDepartmentsAndSubDepartments: fetchRequest(
    '/DepartmentLookup/GetDepartmentsAndSubDepartmentsByName',
    'GET'
  ),
  getDepartmentsByName: fetchRequest(
    '/DepartmentLookup/GetDepartmentsByName',
    'GET'
  ),
  getAllDepartments: fetchRequest('/DepartmentLookup/GetDepartments', 'GET'),
  getIcdList: fetchRequest('/Icd/GetIcdDataByName', 'GET'),
  saveCourse: fetchRequest('/CourseAdmin/Save', 'POST'),
  getCourseList: fetchRequest('/CourseAdmin/GetCourses', 'GET'),
  getCourseDetail: fetchRequest('/CourseAdmin/GetCourseDetails', 'GET'),
  archiveCourse: fetchRequest('/CourseAdmin/Archive', 'POST'),
  publishCourse: fetchRequest('/CourseAdmin/Publish', 'POST'),
  recycleCourse: fetchRequest('/CourseAdmin/Recycle', 'POST'),
  updateCourseTags: fetchRequest('/CourseAdmin/UpdateTags', 'POST'),
  getFakeUsers: fetchRequest('/Impersonate/GetFakeUsers', 'GET'),
  impersonateLike: fetchRequest('/FeedItemAdmin/ImpersonateLike', 'POST'),
  impersonateComment: fetchRequest('/FeedItemAdmin/ImpersonateComment', 'POST'),
  recommendFeed: fetchRequest('/FeedItemAdmin/Recommend', 'POST'),
  unrecommendFeed: fetchRequest('/FeedItemAdmin/Unrecommend', 'POST'),
  updateFeedTags: fetchRequest('/FeedItemAdmin/UpdateTags', 'POST'),
  updateCircles: fetchRequest('/FeedItemAdmin/UpdateCircles', 'POST'),
  getCoursewareTopics: fetchRequest('/Topic/GetCoursewareTopics', 'GET'),
  getGuideDetail: fetchRequest('/Guide/GetGuide', 'GET'),
  getGuideList: fetchRequest('/Guide/GetGuideList', 'GET'),
  getCourses: fetchRequest('/Course/GetCourses', 'GET'),
  getCourse: fetchRequest('/Course/Browse', 'GET'),
  getPubCourse: fetchRequest('/PubCourse/Browse', 'GET'),
  getFeaturedDepartments: fetchRequest('/Guide/GetFeaturedDepartments', 'GET'),
  addBookmark: fetchRequest('/Bookmark/AddBookmark', 'POST'),
  unbookmark: fetchRequest('/Bookmark/Unbookmark', 'POST'),
  getAdminGetOrders: fetchRequest('/Order/AdminGetOrders', 'GET'),
  getCarePatients: fetchRequest('/PatientAdmin/GetCarePatients', 'GET'),
  getPatientDetails: fetchRequest('/PatientAdmin/GetPatientDetails', 'GET'),
  saveMeet: fetchRequest('/MeetupAdmin/Save', 'POST'),
  getMeetups: fetchRequest('/MeetupAdmin/GetMeetups', 'GET'),
  getMeetupDetails: fetchRequest('/MeetupAdmin/GetMeetupDetails', 'GET'),
  recycleMeetup: fetchRequest('/MeetupAdmin/Recycle', 'POST'),
  publishMeetup: fetchRequest('/MeetupAdmin/Publish', 'POST'),
  archivedMeetup: fetchRequest('/MeetupAdmin/Archived', 'POST'),
  exportMeetupToFeed: fetchRequest('/MeetupAdmin/ExportToFeed', 'POST'),

  onboardEnterpriseUsers: fetchRequest('/Provision/OnboardEnterpriseUsers', 'POST'),
  getAllComments: fetchRequest('/Comment/GetAllComments', 'GET'),
  getUserIdentities: fetchRequest('/UserAdmin/GetUserIdentities', 'GET'),
  searchTag: fetchRequest('/Tag/Search', 'GET'),
  searchCircle: fetchRequest('/CircleAdmin/Search', 'GET'),
  saveTag: fetchRequest('/Tag/Save', 'POST'),
  archiveTag: fetchRequest('/Tag/Archive', 'POST'),
  getTagById: fetchRequest('/Tag/GetTagById', 'GET'),
  getGuidesByTag: fetchRequest('/Guide/GetGuidesByTag', 'GET'),
  getOriginalTags: fetchRequest('/Tag/GetOriginalTags', 'GET'),
  getCoursewareCategoryTags: fetchRequest('/Guide/GetCoursewareCategoryTags', 'GET'),
  getChinaMainDegrees: fetchRequest('/UserAdmin/GetChinaMainDegrees', 'GET'),
  getUSAMainDegrees: fetchRequest('/UserAdmin/GetUSAMainDegrees', 'GET'),
  updateUserProfile: fetchRequest('/ProfileAdmin/UpdateUserProfile', 'POST'),
  getApplicationList: fetchRequest('/ApplicationAdmin/GetApplicationList', 'GET'),
  countApplications: fetchRequest('/ApplicationAdmin/CountApplications', 'GET'),
  changeOrderStatus: fetchRequest('/Order/ChangeStatus', 'POST'),
  updateAdminNote: fetchRequest('/Order/UpdateAdminNote', 'POST'),
  getInviteesByUserId: fetchRequest('/UserAdmin/GetInviteesByUserId', 'GET'),
  getInvitedUsers: fetchRequest('/UserAdmin/GetInvitedUsers', 'GET'),
  saveMedWord: fetchRequest('/MedWordAdmin/SaveMedWord', 'POST'),
  uploadMedWords: uploadRequest('/MedWordAdmin/UploadMedWords'),
  publishMedWord: fetchRequest('/MedWordAdmin/Publish', 'POST'),
  getMedWordDetail: fetchRequest('/MedWordAdmin/GetMedWordDetail', 'GET'),
  getMedWordList: fetchRequest('/MedWordAdmin/GetMedWordList', 'GET'),
  exportMedWordToFeed: fetchRequest('/MedWordAdmin/ExportToFeed', 'POST'),
  saveProfession: fetchRequest('/ExperienceAdmin/SaveProfession', 'POST'),
  removeProfession: fetchRequest('/ExperienceAdmin/RemoveProfession', 'POST'),
  saveEducation: fetchRequest('/ExperienceAdmin/SaveEducation', 'POST'),
  removeEducation: fetchRequest('/ExperienceAdmin/RemoveEducation', 'POST'),
  getUserAwards: fetchRequest('/AwardAdmin/GetUserAwards', 'GET'),
  removeAward: fetchRequest('/AwardAdmin/Remove', 'POST'),
  saveAward: fetchRequest('/AwardAdmin/Save', 'POST'),
  savePublications: fetchRequest('/PublicationAdmin/Save', 'POST'),
  getUserPublications: fetchRequest('/PublicationAdmin/GetUserPublications', 'GET'),
  removePublications: fetchRequest('/PublicationAdmin/Remove', 'POST'),
  saveExpertise: fetchRequest('/ProfileAdmin/SaveExpertise', 'POST'),
  deleteExpertise: fetchRequest('/ProfileAdmin/DeleteExpertise', 'POST'),
  saveResearchArea: fetchRequest('/ProfileAdmin/SaveResearchArea', 'POST'),
  deleteResearchArea: fetchRequest('/ProfileAdmin/DeleteResearchArea', 'POST'),
  createGroupByName: fetchRequest('/Group/CreateGroupByName', 'POST'),
  getResidencyTrainingGroups: fetchRequest('/Group/GetResidencyTrainingGroups', 'GET'),
  enablePatientCare: fetchRequest('/UserAdmin/EnablePatientCare', 'POST'),
  requestPharmacistCode: fetchRequest('/UserAdmin/RequestAuthCode', 'POST'),
  setSignature: fetchRequest('/UserAdmin/SetSignature', 'POST'),
  savePharmacistUser: fetchRequest('/UserAdmin/SavePharmacistUser', 'POST'),
  updatePharmacistUser: fetchRequest('/UserAdmin/UpdatePharmacistUser', 'POST'),
  getPharmUsers: fetchRequest('/UserAdmin/GetPharmUsers', 'GET'),
  closePharmAuthority: fetchRequest('/UserAdmin/ClosePharmAuthority', 'POST'),
  openPharmAuthority: fetchRequest('/UserAdmin/OpenPharmAuthority', 'POST'),
  disablePatientCare: fetchRequest('/UserAdmin/DisablePatientCare', 'POST'),
  updatePracticeInfo: fetchRequest('/UserAdmin/UpdatePracticeInfo', 'POST'),
  updateDoctorVerifiedInfo: fetchRequest('/UserAdmin/UpdateDoctorVerifiedInfo', 'POST'),
  getDoctors: fetchRequest('/UserAdmin/GetDoctors', 'GET'),
  getPracticeInfo: fetchRequest('/UserAdmin/GetPracticeInfo', 'GET'),
  getPracticeInfoByUserId: fetchRequest('/UserAdmin/GetPracticeInfoByUserId', 'GET'),
  getCareUsers: fetchRequest('/UserAdmin/GetCareUsers', 'GET'),
  getCareUserDetail: fetchRequest('/UserAdmin/GetCareUserDetail', 'GET'),
  getHospitalLevels: fetchRequest('/Group/GetHospitalLevels', 'GET'),
  exportService: fetchRequest('/Export/Submit', 'POST'),
  getOrders: fetchRequest('/CareOrder/GetOrders', 'GET'),
  getOrderDetail: fetchRequest('/CareOrder/GetOrderDetail', 'GET'),
  cancellOrder: fetchRequest('/CareOrder/CancellOrder', 'GET'),
  remindOrder: fetchRequest('/CareOrder/RemindOrder', 'GET'),
  getRatings: fetchRequest('/CareRating/GetRatings', 'GET'),
  getRatingDetail: fetchRequest('/CareRating/GetRatingDetail', 'GET'),
  acceptRating: fetchRequest('/CareRating/AcceptRating', 'POST'),
  rejectRating: fetchRequest('/CareRating/RejectRating', 'POST'),
  linkPracticeInfoWithUser: fetchRequest('/UserAdmin/LinkPracticeInfoWithUser', 'POST'),
  enablePracticeInfo: fetchRequest('/UserAdmin/EnablePracticeInfo', 'POST'),
  disablePracticeInfo: fetchRequest('/UserAdmin/DisablePracticeInfo', 'POST'),
  getOrderDetailByItemId: fetchRequest('/CareOrder/GetOrderDetailByItemId', 'GET'),
  getExportTypes: fetchRequest('/Export/GetExportTypes', 'GET'),
  getMyExports: fetchRequest('/Export/GetMyExports', 'GET'),
  getAllExports: fetchRequest('/Export/GetAllExports', 'GET'),
  download: fetchRequest('/Export/Download', 'GET'),
  testDownloadExcel: fetchRequest('/User/TestDownloadExcel', 'GET'),
  orderRefund: fetchRequest('/CareOrder/OrderRefund', 'POST'),
  getMedicineList: fetchRequest('/MedicineAdmin/GetMedicineList', 'GET'),
  getDrugList: fetchRequest('/MedicineAdmin/GetDrugList', 'GET'),
  activeDrug: fetchRequest('/MedicineAdmin/ActiveDrug', 'POST'),
  archiveDrug: fetchRequest('/MedicineAdmin/ArchiveDrug', 'POST'),
  getPrimaryMedCategory: fetchRequest('/Medicine/GetPrimaryMedCategory', 'GET'),
  getSecondMedCategoryByPid: fetchRequest('/Medicine/GetSecondMedCategoryByPid', 'GET'),
  getMedicineDetail: fetchRequest('/Medicine/GetMedicineDetail', 'GET'),
  saveMedicine: fetchRequest('/MedicineAdmin/SaveMedicine', 'POST'),
  getMedicineCategories: fetchRequest('/Medicine/GetMedicineCategories', 'GET'),
  getPrescriptionList: fetchRequest('/PrescriptionAdmin/GetPrescriptionList', 'GET'),
  getPrescriptionDetail: fetchRequest('/PrescriptionAdmin/GetPrescriptionDetail', 'GET'),
  passPrescription: fetchRequest('/PrescriptionAdmin/PassPrescription', 'POST'),
  declinePrescription: fetchRequest('/PrescriptionAdmin/DeclinePrescription', 'POST'),
  getPrescriptionDeclineReason: fetchRequest('/PrescriptionAdmin/GetPrescriptionDeclineReason', 'GET'),
  enterSessionByConsultationId: fetchRequest('/CareChatAdmin/EnterSessionByConsultationId', 'POST'),
  getCareOrderMessages: fetchRequest('/CareChatAdmin/GetMessages', 'GET'),
  getCareOrderMessages2: fetchRequest('/CareChatAdmin/GetMessages2', 'GET'),
  getCareSpotTypes: fetchRequest('/CareOpEvent/GetSpotTypes', 'GET'),
  getCareRouteTypes: fetchRequest('/CareOpEvent/GetRouteTypes', 'GET'),
  saveCareOptEvent: fetchRequest('/CareOpEvent/SaveOptEvent', 'POST'),
  getCareEvents: fetchRequest('/CareOpEvent/GetEvents', 'GET'),
  getCareEventDetail: fetchRequest('/CareOpEvent/GetEventDetail', 'GET'),
  archiveCareEvents: fetchRequest('/CareOpEvent/Archive', 'POST'),
  recycleCareEvents: fetchRequest('/CareOpEvent/Recycle', 'POST'),
  saveVerificationDate: fetchRequest('/UserAdmin/SaveVerificationDate', 'POST'),
  getPharmUserDetail: fetchRequest('/UserAdmin/GetPharmUserDetail', 'GET'),
  getUserByUserName: fetchRequest('/UserAdmin/GetUserByUserName', 'POST'),
  getSessionList: fetchRequest('/CareChatAdmin/GetSessionList', 'GET'),
  enterSessionByIdAdmin: fetchRequest('/CareChatAdmin/EnterSessionById2', 'POST'),

  requestOssToken: fetchRequest('/Sts/RequestOssToken', 'GET'),
  requestBaiduToken: fetchRequest('/Sts/RequestBaiduToken', 'GET'),
  requestRAMRole: fetchRequest('/Sts/RequestRAMRole', 'GET'),
  signatureUrl: fetchRequest('/Sts/SignatureUrl', 'GET'),
  getSubChannels: fetchRequest('/UserAdmin/GetSubChannels', 'GET'),
  getDoctorQrCode: fetchRequest('/UserAdmin/GetDoctorQrCode', 'GET'),
  getPacks: fetchRequest('/ServicePack/GetPacks', 'GET'),
  selectDoctor: fetchRequest('/ServicePack/SelectDoctor', 'POST'),
  getSurveyById: fetchRequest('/SurveyService/GetSurveyById', 'GET'),
  uploadCaseHistory: fetchRequest('/PatientAdmin/UploadCaseHistory', 'POST'),
  updateManagementPlan: fetchRequest('/ServicePack/UpdateManagementPlan', 'POST'),
  uploadManagementPlan: fetchRequest('/ServicePack/UploadManagementPlan', 'POST'),
  getAdminAuditLogs: fetchRequest('/LogAdmin/GetAdminAuditLogs', 'GET'),
  getTemplates: fetchRequest('/SurveyService/GetTemplates', 'GET'),
  getTemplateById: fetchRequest('/SurveyService/GetTemplateById', 'GET'),
  archiveTemplate: fetchRequest('/SurveyService/ArchiveTemplate', 'POST'),
  uploadCareSurveyTemplate: fetchRequest('/Provision/UploadCareSurveyTemplate', 'POST'),
  uploadManagementTemplate: fetchRequest('/Provision/UploadManagementTemplate', 'POST'),
  getPainManagementTemplates: fetchRequest('/PainManagementAdmin/GetTemplates', 'GET'),
  archivePainManagementTemplate: fetchRequest('/PainManagementAdmin/ArchiveTemplate', 'POST'),
  getPainManagementTemplateDetail: fetchRequest('/PainManagementAdmin/GetTemplateDetail', 'GET'),
  getPainManagementList: fetchRequest('/PainManagementAdmin/GetPainManagementList', 'GET'),
  getPainManagementDetail: fetchRequest('/PainManagementAdmin/GetPainManagementDetail', 'GET'),
  getManagementOrders: fetchRequest('/CareOrder/GetManagementOrders', 'GET'),
  getParcelState: fetchRequest('/Logistics/GetParcelState', 'GET'),
  getShippers: fetchRequest('/Logistics/GetShippers', 'GET'),
  updateShipmentList: fetchRequest('/CareOrder/UpdateShipmentList', 'POST'),
  getGoodsBySKUNames: fetchRequest('/Goods/GetGoodsBySKUNames', 'GET'),
  getPatientMedRecords: fetchRequest('/PatientAdmin/GetPatientMedRecords', 'GET'),
  uploadPatientMedRecord: fetchRequest('/PatientAdmin/UploadPatientMedRecord', 'POST'),
  deletePatientMedRecord: fetchRequest('/PatientAdmin/DeletePatientMedRecord', 'POST'),
  getConsultationReports: fetchRequest('/PatientAdmin/GetConsultationReports', 'GET'),
  uploadConsultationReport: fetchRequest('/PatientAdmin/UploadConsultationReport', 'POST'),
  deleteConsultationReport: fetchRequest('/PatientAdmin/DeleteConsultationReport', 'POST'),
  updateDoctorVisible: fetchRequest('/PainManagementAdmin/UpdateDoctorVisible', 'POST'),
};
export default apiService;
